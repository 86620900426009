"use client";

import {
  type MobileRedirectRoute,
  getMobileRedirectRoute,
} from "#app/_ui/components/mobileRedirects/MobileRedirectFunctions";
import { Icon } from "#ui/icon";
import { Logo } from "#ui/logo";
import { addDays } from "date-fns";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { isAndroid, isIOS, isMobile, isSafari } from "react-device-detect";
import redirectSettings from "./redirectSettings.json";

const FORCE_MOBILE_KEY = "forceMobile";
const USER_CLOSED_BANNER_KEY = "dontShowBanner";

/** Retrieves a session item by key */
function getCache(cacheKey: string) {
  return window.sessionStorage.getItem(cacheKey);
}

/** Sets the session `cacheKey` to today's date + extra time */
function setCache(cacheKey: string, value: string) {
  window.sessionStorage.setItem(cacheKey, value);
}

/** Removes a session item by key */
function removeCache(cacheKey: string) {
  window.sessionStorage.removeItem(cacheKey);
}

/** Returns milliseconds string of now + 30days */
function createCacheValue() {
  return addDays(new Date(), 30).toString();
}

function getIsCacheDateGteCurrent(
  cacheKey: string,
  ignoreDate: boolean,
): boolean {
  const cacheVal = getCache(cacheKey);
  if (cacheVal === null) {
    return false;
  }
  if (ignoreDate) {
    return true;
  }
  const createdDate = new Date(Date.parse(cacheVal));
  const currentDate = new Date();
  return currentDate <= createdDate;
}

function checkDontShowBanner(): boolean {
  const isCacheDateGteCurrent = getIsCacheDateGteCurrent(
    USER_CLOSED_BANNER_KEY,
    true,
  );
  if (isCacheDateGteCurrent) {
    removeCache(USER_CLOSED_BANNER_KEY);
  }
  return isCacheDateGteCurrent;
}

function checkForceMobile(): boolean {
  return getIsCacheDateGteCurrent(FORCE_MOBILE_KEY, true);
}

function goToMobile(route: MobileRedirectRoute | null) {
  if (!route?.route) {
    return;
  }

  var storeUrl = "";
  if (isAndroid) {
    storeUrl = redirectSettings.mobileStoreURLAndroid;
  } else if (isIOS && !isSafari) {
    storeUrl = redirectSettings.mobileStoreURLIOS;
  }

  if (storeUrl) {
    const timeOutId = setTimeout(function () {
      setTimeout(function () {
        // iOS has a are you sure popup that we need to bypass
        if (document.visibilityState !== "hidden") {
          window.location.href = storeUrl;
        }
      }, 500);
    }, 2000);
    window.addEventListener("visibilitychange", () => clearTimeout(timeOutId));
    window.addEventListener("pagehide", () => clearTimeout(timeOutId));
  }

  const paramsStr = route.params.toString();
  window.location.href = paramsStr
    ? [route.route, paramsStr].join("?")
    : route.route;
}

// -----------------------------------------------------------------------------

export function MobileRedirect() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [showBanner, setShowBanner] = useState(false);

  const currentRoute: MobileRedirectRoute = getMobileRedirectRoute(
    pathname,
    searchParams,
  );

  useEffect(() => {
    const shouldShowBanner =
      currentRoute?.route != null &&
      isMobile &&
      !isSafari &&
      !checkDontShowBanner();

    const shouldGoToMobile = checkForceMobile() && showBanner;

    setShowBanner(shouldShowBanner);

    if (shouldGoToMobile) {
      goToMobile(currentRoute);
    }
  }, [currentRoute, showBanner]);

  if (!showBanner) {
    return null;
  }

  return (
    <div
      aria-live="polite" // immediately announce to users
      className="flex h-90px flex-row-reverse items-center gap-6px bg-primary-120 pl-10px pr-20px text-white"
    >
      {/* First in source = first read by a11y */}
      <button
        onTouchEnd={() => {
          setCache(FORCE_MOBILE_KEY, createCacheValue());
          goToMobile(currentRoute);
        }}
        className="flex w-full items-center gap-10px text-left"
      >
        <div className="grid size-62px shrink-0 place-content-center rounded-xl bg-white">
          <Logo aria-hidden="true" layout="mark-only" size="xs" />
        </div>
        <div className="space-y-7px">
          <div aria-hidden="true" className="text-[length:17px] leading-none">
            Baird Online
          </div>
          <div className="text-[length:14px] leading-none text-white/50">
            {/* This is the only text a11y should announce aside from the dismiss button */}
            Open in the Baird Online App
          </div>
        </div>
        <div
          className="ml-auto grid h-27px place-content-center rounded-full bg-white px-12px text-[length:16px] uppercase leading-none text-primary-120"
          aria-hidden="true"
        >
          Open
        </div>
      </button>
      <button
        onTouchEnd={() => {
          setCache(USER_CLOSED_BANNER_KEY, createCacheValue());
          setShowBanner(false);
        }}
      >
        <Icon name="ms-close" className="size-15px text-white/40" />
        <span className="sr-only">Dismiss</span>
      </button>
    </div>
  );
}
