import { cva, type VariantProps } from "./style.utils";

export type LogoVariants = VariantProps<typeof logoVariants>;

export const logoVariants = cva({
  base: "h-auto shrink-0",
  variants: {
    layout: {
      "mark-only": "",
      "mark-and-word": "",
      "word-only": "",
    },
    palette: {
      unset: null,
      white: [
        // background
        "[&_[data-part=mark-bg]]:fill-white",
        // foreground
        "[&_[data-part=mark-fg]]:fill-primary-100 [&_[data-part=word]]:fill-white",
      ],
      allwhite: [
        // background
        "[&_[data-part=mark-bg]]:fill-white",
      ],
    },
    size: {
      unset: null,
      xs: "",
      sm: "",
      md: "",
      lg: "",
    },
  },
  compoundVariants: [
    {
      layout: "mark-only",
      size: "xs",
      className: "w-[43.65px]", // ~40px height
    },
    {
      layout: "mark-only",
      size: "sm",
      className: "w-[60px]", // ~55px height
    },
    {
      layout: "mark-only",
      size: "md",
      className: "w-[65.45px]", // ~60px height
    },
    {
      layout: "mark-only",
      size: "lg",
      className: "w-[87.27px]", // ~80px height
    },
    {
      layout: "word-only",
      size: "xs",
      className: "w-[118.4px]", // ~18.41px height
    },
    {
      layout: "word-only",
      size: "sm",
      className: "w-[157.95px]", // ~25.32px height
    },
    {
      layout: "word-only",
      size: "md",
      className: "w-[172.23px]", // ~27.61px height
    },
    {
      layout: "word-only",
      size: "lg",
      className: "w-[229.7px]", // ~36.82px height
    },
    {
      layout: "mark-and-word",
      size: "xs",
      className: "w-[186.4px]", // ~40px height
    },
    {
      layout: "mark-and-word",
      size: "sm",
      className: "w-[256.25px]", // ~55px height
    },
    {
      layout: "mark-and-word",
      size: "md",
      className: "w-[279.54px]", // ~60px height
    },
    {
      layout: "mark-and-word",
      size: "lg",
      className: "w-[372.72px]", // ~80px height
    },
  ],
  defaultVariants: {
    layout: "mark-and-word",
    palette: "unset",
    size: "md",
  },
});
