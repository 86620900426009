import type { Metadata } from "next";
import redirectSettings from "./redirectSettings.json";

export interface MobileRedirectRoute {
  route: string | null;
  params: URLSearchParams;
}

export function generateMobileRedirectMetaData(
  path: string,
  searchParams: URLSearchParams,
): Metadata {
  const route = getMobileRedirectRoute(path, searchParams);
  if (!route?.route) {
    return {};
  }
  const paramsStr = route.params.toString();
  return {
    itunes: {
      appId: "777691707",
      appArgument: paramsStr ? [route.route, paramsStr].join("?") : route.route,
    },
  };
}

export function getMobileRedirectRoute(
  path: string,
  searchParams: URLSearchParams,
): MobileRedirectRoute {
  const redirectsWithPathname = redirectSettings.redirects.filter(
    (r) => !!path && path.toLowerCase().startsWith(r.sourcePath.toLowerCase()),
  );

  const result = redirectsWithPathname.find(
    (r) =>
      r.sourceParams.filter((x) =>
        x.value ? searchParams.has(x.key, x.value) : searchParams.has(x.key),
      ).length >= r.sourceParams.length,
  );
  return {
    route: result ? result.targetRoute : null,
    params: searchParams,
  };
}
